import { fetchStream, windowURL } from '@/src/utils/common'

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['sets', 'set']

  newSet (_event) {
    // If miniature set is the default one and is empty, its modal will open instead of fetching a new stream
    if (this.setTargets[0]?.hasAttribute('data-empty')) {
      return this._dispatchEditDefaultSet()
    }

    fetchStream(
      'POST',
      `${windowURL}/new_set`,
      {
        index: this.setTargets.length,
        target: this.setsTarget.id
      },
      template => {
        template.querySelector('.nrm-set').setAttribute('new-set', '')
      }
    )
  }

  removeSet (event) {
    const closestSet = event.currentTarget.closest(`.${this.setTarget.className}`)
    closestSet.remove()
  }

  _dispatchEditDefaultSet () {
    const editDefaultEvent = new CustomEvent('miniatures:edit-default')
    this.setTargets[0].dispatchEvent(editDefaultEvent)
  }
}
