export default class FormWatcher {
  constructor(startNode, debug = false) {
    this.instanceMap = new Map();
    this.debug = debug;
    this.collect(startNode);
  }

  collect(startNode) {
    startNode.querySelectorAll("[data-form-id]").forEach((formNode) => {
      const instanceId = formNode.dataset.formId;
      const instanceModel = formNode.dataset.formModel;
      const instanceObj = new FormInstance(instanceId, instanceModel);

      formNode.querySelectorAll("[data-form-field]").forEach((fieldNode) => {
        const { formField, formTrack } = fieldNode.dataset;

        if (formTrack === "false") {
          return;
        }

        instanceObj.addField(fieldNode.name, formField, fieldNode.value);
        fieldNode.removeAttribute("name");
      });

      this.instanceMap.set(instanceId, instanceObj);
    });

    const map = Object.fromEntries(this.instanceMap);
  }

  reconcile(fieldNode, changeCallback, unchangeCallback) {
    const formNode = fieldNode.closest("[data-form-id]");
    const { formId } = formNode.dataset;
    const { fields } = this.instanceMap.get(formId);
    const instanceField = fields[fieldNode.dataset.formField];
    instanceField.update(fieldNode.value);

    if (this.debug) {
      console.log({ diff: this.diff() });
    }

    instanceField.didChange()
      ? changeCallback(instanceField)
      : unchangeCallback(instanceField);
  }

  diff() {
    const initial = Object.fromEntries(this.instanceMap);
    console.log({ initial });

    const changes = Object.values(initial).map((form) => {
      const changed = form.changedFields();

      if (JSON.stringify(changed) === "{}") {
        return;
      }

      return { ...form, changed };
    });

    return changes.filter(Boolean);
  }
}

class FormInstance {
  constructor(id, model) {
    this.id = id;
    this.model = model;
    this.fields = {};
  }

  addField(identifier, name, value) {
    this.fields[name] = new FormField(identifier, name, value);
  }

  updateField(name, newValue) {
    this.fields[name].update(newValue);
  }

  changedFields() {
    const fields = Object.values(this.fields).filter((field) =>
      field.didChange()
    );

    return fields.reduce((result, field) => {
      result[field.name] = field.value;
      return result;
    }, {});
  }
}

class FormField {
  constructor(identifier, name, value) {
    this.name = name;
    this.identifier = identifier;
    this.value = value;
    this.original = value;
  }

  update(newValue) {
    this.value = newValue;
    this.dirty = true;
  }

  didChange() {
    return this.value !== this.original;
  }
}
