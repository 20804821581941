import Choices from 'choices.js'
import { Controller } from "stimulus";
export default class extends Controller {

  connect() {
    this.initializeChoices();
  }

  initializeChoices() {
    const selects = document.querySelectorAll('select')
    selects.forEach(select => {
      new Choices(select, {
        searchResultLimit: 50,
        position: 'bottom'
      });
    })
  }
}
