import { useRedirectOnSuccess } from "@/src/mixins/useRedirectOnSuccess";
import {
  fetchStream,
  toCamelCase,
  toTitleCase,
  windowURL,
} from "@/src/utils/common";
import FormWatcher from "@/src/utils/form-watcher";
import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["container", "add"];

  connect() {
    this.formWatcher = new FormWatcher(this.containerTarget);
    this.element[toCamelCase(this.identifier)] = this;
    useRedirectOnSuccess(this);
  }

  input(event) {
    const { currentTarget } = event;
    this.formWatcher.reconcile(
      currentTarget,
      (instanceField) => {
        currentTarget.setAttribute("name", instanceField.identifier);
      },
      () => {
        currentTarget.removeAttribute("name");
      }
    );
  }

  add() {
    const dummy = document.createElement("span");
    dummy.id = "dummy-target";
    dummy.className = "hidden";
    this.addTarget.after(dummy);

    fetchStream(
      "GET",
      `${windowURL}/new`,
      {
        target: dummy.id,
      },
      (content) => this.formWatcher.collect(content)
    );
  }

  delete(event) {
    const { currentTarget } = event;
    const box = currentTarget.closest(".box");
    const { formModel, formId } = box.dataset;
    box.querySelectorAll("[data-form-field]").forEach((field) => {
      field.setAttribute(
        "name",
        `delete_params[${toTitleCase(formModel)}][${formId}]`
      );
    });
    box.classList.add("fade-out");
    setTimeout(() => box.classList.add("hidden"), 500);
  }
}
