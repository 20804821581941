import toastr from 'toastr'
import { toggleLoading } from '../utils/common'

export const useRedirectOnSuccess = controller => {
  Object.assign(controller, {
    onSend () {
      toggleLoading(document.body, true, 'Saving...')
    },
    onSuccess (event) {
      const [, , xhr] = event.detail
      const { status, response } = xhr
      const data = JSON.parse(response)
      if (data?.error) {
        toastr.error(data.error)
      } else {
        if (status === 200) {
          toastr.success('Changes saved successfully')
          if (data?.redirect_to) window.location.href = data.redirect_to
        } else {
          toastr.error('An error occurred while saving. Try again later.')
        }
      }
    },
    onComplete () {
      toggleLoading(document.body, true)
    }
  })
}
