import {Controller} from "stimulus";
import {fetchStream, windowURL} from "@/src/utils/common";
import "./component.scss";
import Uppy from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";

const s3_folder = "file_elements/files"

export default class extends Controller {
    static targets = ["container", "fileItem", "fileInput"]

    connect() {
        this.uppy = new Uppy({
            debug: true,
            allowMultipleUploads: true,
        }).use(AwsS3Multipart, {
            companionUrl: "/",
        });

        this.fileInputTargets.forEach(target => this._buildInputName(target))
    }

    add() {
        fetchStream(
            "GET",
            `${windowURL}/new_file_element`,
            {
                target: this.containerTarget.id,
            },
            (content) => {
                Array.from(content.querySelectorAll("[data-next-action]")).forEach(input => this._buildInputName(input))
            }
        );
    }

    remove(event) {
        const closestElement = event.currentTarget.closest(".nrm-file__item")
        Array.from(closestElement.querySelectorAll("[data-next-action]")).forEach(input => {
            input.dataset.nextAction = "delete"
            this._buildInputName(input)
        })
        closestElement.classList.add("hidden")
    }

    upload(event) {
        const payload = Object.fromEntries(Array.from(this.containerTarget.querySelectorAll("[data-next-action]"))
            .map(input => [input.dataset.objectFieldName, input.value]))
        const file = event.target.files[0]

        if (!file) {
            return;
        }

        const {name, type} = file;
        const escapedName = encodeURI(name.replace(/\s/gi, '-'))

        const fileId = this.uppy.addFile({
            source: "file input",
            data: file,
            name: escapedName,
            type,
        })

        const objectId = event.currentTarget.dataset.objectId;
        const nextAction = event.currentTarget.dataset.nextAction;
        const partitionId = objectId.match(/.{1,4}/g).join("/");

        this.uppy.setFileMeta(fileId, {
            key: `${s3_folder}/${partitionId}/${escapedName}`,
        });

        this._uploadToS3({
            ...payload,
            nextAction,
            id: objectId,
            "file_file_name": escapedName
        })
    }

    highlight({ type, target }) {
        switch(type) {
            case "dragenter":
                return target.classList.add('dragging')
            case "dragleave":
                return target.classList.remove('dragging')
            case "drop":
                return target.classList.add('file-dropped')
        }
    }

    async _uploadToS3(payload) {
        const result = await this.uppy.upload();
        result.successful.forEach(({uploadURL}) => {
            this._processUploadedFile({...payload, uploadURL})
        });
    }

    _processUploadedFile(payload) {
        const url = payload.nextAction === "update" ? `${windowURL}/update_file_element` : windowURL
        const action = payload.nextAction === "update" ? "update_file" : "create_file"

        fetchStream(
            "POST",
            url,
            {[action]: payload, target: `file[${payload.id}]`},
            (content) => {
                Array.from(content.querySelectorAll("[data-next-action]")).forEach(input => this._buildInputName(input))
            }
        );
    }

    _buildInputName(input) {
        const {fieldName, nextAction, objectId, objectFieldName} = input.dataset
        input.name = `${fieldName}[${nextAction}][${objectId}][${objectFieldName}]`
    }
}

