import { Controller } from 'stimulus'
import { useNamespacedClass } from '@/src/mixins/useNamespacedClass'
import './component.module.scss'

const SELECTED = 'selected'
const MAX_SELECTION = 4

export default class extends Controller {
  static targets = ['gallery', 'picture'];
  static values = {
    multiple: Boolean,
    selected: Array,
    index: Number
  }

  connect () {
    useNamespacedClass(this)
    this._updatedSelectedPictures()
    this.selectedClass = this.namespaceClass(SELECTED)
  }

  select ({ currentTarget: targetPicture }) {
    const { value, src } = targetPicture.dataset
    const isSelected = targetPicture.matches(`.${this.selectedClass}`)

    if (!this.multipleValue) {
      if (!isSelected) {
        this.pictureTargets.forEach(pic => pic.classList.remove(this.selectedClass))
        targetPicture.classList.toggle(this.selectedClass)
        this._dispatchSelectedEvent('featured', [{ value, src }])
      }
      return
    }

    let newSelected = []

    if (isSelected) {
      targetPicture.classList.remove(this.selectedClass)
      targetPicture.removeAttribute('data-count')
      newSelected = this.selectedValue.filter(val => val !== value)
    } else {
      if (this.selectedValue.length >= MAX_SELECTION) return
      targetPicture.classList.add(this.selectedClass)
      newSelected = [...this.selectedValue, value]
    }

    const selectedPictures = this._updatedSelectedPictures(newSelected)
    this._dispatchSelectedEvent('miniatures', selectedPictures, this.indexValue)
  }

  _dispatchSelectedEvent (type, values, miniatureSet) {
    const selectedEvent = new CustomEvent('imagePicker:selected', {
      detail: { type, values, miniatureSet },
      bubbles: true,
      cancelable: true
    })
    this.galleryTarget.dispatchEvent(selectedEvent)
  }

  _updatedSelectedPictures (selectedItems = this.selectedValue) {
    const selectedPictures = this.pictureTargets.reduce((acc, pic) => {
      const { value, src } = pic.dataset
      const index = selectedItems.indexOf(value)
      if (index >= 0) {
        this.multipleValue && pic.setAttribute('data-count', index + 1)
        acc[index] = { value, src }
      }
      return acc
    }, [])
    this.selectedValue = selectedItems
    return selectedPictures
  }
}
