import {useRedirectOnSuccess} from "@/src/mixins/useRedirectOnSuccess";
import {fetchStream, toCamelCase, windowURL} from "@/src/utils/common";
import FormWatcher from "@/src/utils/form-watcher";
import UploadManager from "@/src/utils/upload-manager";
import {Controller} from "stimulus";
import Uppy from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";

const ACCEPTED_FILE_TYPES = /(\.|\/)(gif|jpe?g|png)$/i
const FILE_SIZE_WARNING_THRESHOLD = 31457280 // 30 MB

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.uppy = new Uppy({
      debug: true,
      allowMultipleUploads: true,
      restrictions: {
        allowedFileTypes: ["image/*"],
      },
    }).use(AwsS3Multipart, {
      companionUrl: "/",
    });

    this.formWatcher = new FormWatcher(document);
    this.uploader = new UploadManager(this.containerTarget);
    this.uppy.on("upload-progress", (file, progress) =>
      this.uploader.updateProgress(file, progress)
    );
    this.element[toCamelCase(this.identifier)] = this;
    useRedirectOnSuccess(this);
  }

  select(event) {
    const { currentTarget, target } = event;
    if (currentTarget !== target) {
      return;
    }
    currentTarget.classList.toggle("selected");
  }

  input(event) {
    this.field_reconciliation(event.currentTarget);
  }

  field_reconciliation(node) {
    this.formWatcher.reconcile(
      node,
      (instanceField) => {
        node.setAttribute("name", instanceField.identifier);
      },
      () => {
        node.removeAttribute("name");
      }
    );
  }

  add(event) {
    const { klass, mediaProviderId } = event.target.dataset;
    fetchStream(
      "POST",
      `${windowURL}/new_video`,
      {
        klass,
        media_provider_id: mediaProviderId,
        target: "media_data",
      },
      (content) => this.formWatcher.collect(content)
    );
  }

  upload(event) {
    const target = event.target;
    const files = target.files;
    let globalCount = Number(this.element.dataset.globalCount)

    Array.from(files).forEach(file => {
      try {
        const { name, type, size } = file

        if (!ACCEPTED_FILE_TYPES.test(type)) {
          alert(`${name.split('.').pop().toUpperCase()} format is not supported. Please upload your image in JPEG, PNG or GIF.`)
          return
        }

        if (size >= FILE_SIZE_WARNING_THRESHOLD) {
          const sizeInMB = Math.round(size / 1024 / 1024)
          // eslint-disable-next-line max-len
          const confirmUpload = confirm(`The image "${name}" is too large (${sizeInMB} MB).\nIt might take quite long to process and prevent the article from being exported.\nAre you sure you want to proceed?\n(Click cancel to skip it)`)
          if (!confirmUpload) return
        }

        globalCount = globalCount + 1
        const nameParts = name.split(".");
        const filename = nameParts.slice(0,-1).join("-");
        const fileFormat = nameParts[nameParts.length - 1];
        const fileId = this.uppy.addFile({
          source: "file input",
          data: file,
          name: `${filename}_${globalCount}.${fileFormat}`,
          type,
        });

        this.uploader.buildSkeleton(fileId, file.name);
        this._uploadToS3({
          media_type: target.dataset.klass,
          article_id: target.dataset.articleId,
          media_provider_id: target.dataset.mediaProviderId,
        });
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          console.log("Restriction error:", err);
        } else {
          // handle other errors
          console.error(err);
        }
      }
    });
  }

  async _uploadToS3(payload) {
    const result = await this.uppy.upload();
    result.successful.forEach((file) => {
      this.uploader.processUploaded(file, payload, (media) =>
        this.formWatcher.collect(media)
      );
    });
  }
}
