import "@loadingio/loading-bar";
import { camelCaseToDash } from "./common";

export function Bar(wrap, options = {}) {
  options = camelCaseToDash(options);
  wrap = document.getElementById(wrap);
  const bar = new ldBar(wrap, options);

  const fileNameContainer = document.createElement("p");
  fileNameContainer.classList.add("caption", "file-name");
  fileNameContainer.innerHTML = `Uploading <strong>${
    options["file-name"] || ""
  }</strong>`;
  wrap.parentNode.appendChild(fileNameContainer);

  return {
    wrap,
    bar,
    set: function (percent) {
      this.bar.set(percent);
    },
  };
}

export default Bar;
