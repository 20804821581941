import { Controller } from 'stimulus'
import sortable from 'html5sortable/dist/html5sortable.es'
import './component.scss'

export default class extends Controller {
  static targets = ['featured', 'fixedContainer', 'sortableContainer']

  connect () {
    sortable('.js-sortable', {
      placeholderClass: 'gallery__placeholder',
      hoverClass: 'gallery__item--hover'
    })
    this.featuredImage = this.hasFeaturedTarget ? this.featuredTarget : ''
  }

  forceGalleryUpdate (e) {
    const { type, values: images, miniatureSet = 0 } = e.detail
    if (miniatureSet > 0) return

    const imageValues = images.map(el => el.value)

    const [info, ...fixedElements] = this.fixedContainerTarget.children
    const sortableElements = this.sortableContainerTarget.children
    const allImages = [...fixedElements, ...sortableElements]

    if (type === 'featured') { // Change on Featured Image
      const newFeatured = allImages.find(el => el.dataset.id === imageValues[0])
      if (!newFeatured) {
        this.fixedContainerTarget.removeChild(this.featuredImage)
      } else if (newFeatured && !this.featuredImage) {
        this.fixedContainerTarget.children[0].insertAdjacentElement('afterend', newFeatured)
      } else {
        this.fixedContainerTarget.replaceChild(newFeatured, this.featuredImage)
      }
      this.featuredImage = newFeatured
    } else { // Change on Miniatures
      const newMinis = allImages.filter(el => imageValues.includes(el.dataset.id))
      this.fixedContainerTarget.replaceChildren(info, this.featuredImage, ...newMinis)
    }

    // Update sortable set with the rest of the images
    const unusedImages = allImages
      .filter(el => !this.fixedContainerTarget.contains(el))
    this.sortableContainerTarget.replaceChildren(...unusedImages)

    sortable('.js-sortable')
  }
}
