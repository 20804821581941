import { Controller } from "@stimulus/core";
import "./component.scss"

export default class extends Controller {
  static targets = ['modal', 'preview']

  selectImage() {
    this.modalTarget?.open()
  }

  closeModal() {
    this.modalTarget?.close()
  }

  featuredUpdated (e) {
    const [featured] = e.detail.values
    const { src, value } = featured
    const [img, input] = this.previewTarget.children

    img.src = src
    input.value = value
    this.previewTarget.classList.remove('afd-hide')
  }
}