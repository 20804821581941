/**
 * This Modal is a web component.
 * In order to use it:
 * 1. Add the <hd-modal /> tag in your template
 * 2. If you have content for the modal's header or footer, provide the proper content for each inside the <hd-modal />
 *    You'll need to mark those with the attributes slot="header" or slot="footer"
 *    Also provide the content for the modal. This can be anything and doesn't need a special attribute.
 * 3. To interact with the modal in JS, just select the element as any other DOM element.
 *    You'll find that this element has 2 additional methods: open() and close()
 *    Example:
 *    const modal = document.querySelector('hd-modal') // Probably with a more specific selector
 *    modal.open()
 *    modal.close()
 */
class ModalComponent extends HTMLElement {
  styles () {
    return `
      .modal {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 999999;
      }

      :host([visible]) .modal {
        display: block;
      }

      .apear {
        .modal-content {
          right: 0 !important;
        }
      }

      .gpt-modal-aside {
        .backdrop {
          position: relative;
          background-color: transparent;
        }
        .modal-content {
          position: absolute;
          inset: 0 -50vw 0 auto;
          box-shadow: -10px 0 70px rgba(0,0,0,.7);
          width: 35vw;
          height: 100vh;
          transition: all 0.27s ease;
        }
      }

      .backdrop {
        background: rgba(0,0,0,.8);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .modal-content {
        position: relative;
        height: 80vh;
        width: 75vw;
        max-width: 1040px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #FFF;
        border-radius: 4px;
        overflow: hidden;
      }

      :host([full-screen]) .modal-content {
        height: 100%;
        width: 100%;
        max-width: initial;
        border-radius: 0;
      }

      .header {
        display: flex;
        justify-content: space-between;
        padding: 32px 32px 24px;
        background: #FFF;
      }

      .header .close {
        position: absolute;
        top: 0;
        right: 0;
        color: #929292;
        height: 33px;
        width: 33px;
        font-size: 15px;
        border: 0;
        padding: 0;
        background: none;
        cursor: pointer;
      }

      .modal-body {
        padding: 24px 32px;
        height: 100%;
        max-height: 100%;
        overflow: auto;
      }

      .footer {
        padding: 24px;
        display: flex;
        background: #FFF;
      }
    `
  }

  constructor () {
    super()
    this.attachShadow({ mode: 'open' })

    this.shadowRoot.innerHTML = `
      <style>${this.styles()}</style>
      <div class="modal" id="modal">
        <div class="backdrop close">
          <div class="modal-content">
            <div class="header">
              <slot name="header"></slot>
              <button class="close">✕</button>
            </div>
            <div class="modal-body">
              <slot></slot>
            </div>
            <div class="footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    `
  }

  static get observedAttributes () {
    return ['visible', 'right-modal']
  }

  attributeChangedCallback (name, _oldValue, newValue) {
    if (name === 'visible') newValue ? this.open() : this.close()
    if (name === 'right-modal') {
      const shadow = this.shadowRoot
      const childNodes = Array.from(shadow.childNodes)
      childNodes.forEach(e => e.id === 'modal' && e.classList.add('gpt-modal-aside'))
    }
  }

  connectedCallback () {
    this._modalContent = this.shadowRoot.querySelector('.modal-content')
    this._closeBtns = this.shadowRoot.querySelectorAll('.close')

    this._modalContent.addEventListener('click', e => e.stopPropagation())
    this._closeBtns.forEach(btn => {
      btn.addEventListener('click', this.close)
    })
  }

  open = callback => {
    this.shadowRoot.querySelector('.backdrop').classList.add('apear')
    if (!this.getAttribute('visible')) this.setAttribute('visible', true)
    document.addEventListener('keydown', this._hideOnEscape)
    document.body.style.overflow = 'hidden'
    callback && callback()
  }

  close = () => {
    this.shadowRoot.querySelector('.modal-content').classList.remove('apear')
    document.removeEventListener('keydown', this._hideOnEscape)
    this.removeAttribute('visible')
    document.body.style.overflow = 'auto'
  }

  _hideOnEscape = e => {
    e.key === 'Escape' && this.close()
  }
}

customElements.define('hd-modal', ModalComponent)
