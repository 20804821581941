import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['widget']

  switchPreview (e) {
    const { value: activeSite } = e.target
    const showAll = activeSite === 'ALL'
    this.widgetTargets.forEach(widget => {
      const { site } = widget.dataset
      if (showAll || activeSite === site) {
        widget.classList.remove('afd-hide')
      } else {
        widget.classList.add('afd-hide')
      }
    })
  }
}
