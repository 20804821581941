import bar from "@/src/utils/loading-card";
import { fetchStream } from "./common";

export default class UploadManager {
  constructor(startNode) {
    this.uploadStatusBarMap = new Map();
    this.startNode = startNode;
  }

  buildSkeleton(fileId, fileName) {
    const skeleton = new Skeleton(this.startNode, fileId, fileName);
    this.uploadStatusBarMap.set(fileId, skeleton);
  }

  updateProgress(file, progress) {
    const percentage = Math.floor(
      (progress.bytesUploaded / progress.bytesTotal) * 100
    );

    this.uploadStatusBarMap.get(file.id).setProgress(percentage);
  }

  processUploaded(file, payload, afterUploadHook) {
    this._createMedia(file, payload, (media) => afterUploadHook(media));
  }

  async _createMedia(file, payload, afterCreateHook) {
    const fileId = file.id;

    fetchStream(
      "POST",
      window.location.href,
      {
        create_media: {
          tmp_image_url: file.uploadURL,
          image_file_name: file.name,
          image_content_type: file.type,
          image_file_size: file.size,
          skeleton_id: this.uploadStatusBarMap.get(fileId).elementId,
          ...payload,
        },
      },
      (content) => afterCreateHook(content)
    );
  }
}

class Skeleton {
  constructor(startNode, fileId, fileName) {
    this.startNode = startNode;
    this.elementId = `skeleton-${fileId}`;
    this.element = this._buildContainer(startNode, this.elementId);
    this.bar = this._addLoadingBar(this.element, fileName, fileId);
  }

  setProgress(percentage) {
    this.bar.set(percentage);
  }

  _buildContainer(startNode, elementId) {
    const card = document.createElement("div");
    card.id = elementId;
    card.classList.add("box", "skeleton");
    startNode.prepend(card);
    return card;
  }

  _addLoadingBar(container, fileName, fileId) {
    const uploadBarContainer = document.createElement("div");
    const elementId = `percentage-${fileId}`;
    uploadBarContainer.id = elementId;
    container.appendChild(uploadBarContainer);

    return bar(elementId, {
      type: 'fill',
      fillBackgroundExtrude: 0,
      img: this.startNode.dataset.uploadPlaceholder,
      imgSize: '75,150',
      fileName: fileName
    })
  }
}
