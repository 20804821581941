
class TabsComponent extends HTMLElement {
  constructor () {
    super()
    this.attachShadow({ mode: 'open' })

    this.shadowRoot.innerHTML = `
      <style>${this.styles()}</style>
      <div class="container">
        <header>
          <nav id="nav"></nav>
        </header>
        <section>
          <slot id="slotted-content"></slot>
        </section>
      </div>
    `
  }

  styles () {
    return `
      .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
      }

      nav {
        background: white;
      }

      nav a {
        display: inline-block;
        padding: 16px 16px 14px;
        font-weight: 600;
        color: #00308E;
        text-align: center;
        cursor: pointer;
        border-bottom: 2px solid transparent;
      }

      nav .active {
        border-bottom-color: #00308E;
      }

      section {
        height: 100%;
        overflow: hidden;
        padding-top: 16px;
      }

      section slot {
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        overflow: scroll hidden;
        scroll-behavior: smooth;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      section slot::-webkit-scrollbar {
        display: none;
      }

      ::slotted(*) {
        min-width: 100%;
      }
    `
  }

  connectedCallback () {
    const slot = this.shadowRoot.getElementById('slotted-content')
    this.tabs = slot?.assignedElements()
    this._attachTabLabels(this.tabs)

    // TODO: Pass custom active tab as attribute
    this.switchTab(this.tabLabelElements[0])
  }

  switchTab = tabClicked => {
    this.navContainer.querySelector('.active')?.classList.remove('active')
    tabClicked.classList.add('active')

    const targetTab = this.tabs.find(tab => tab.matches(tabClicked.dataset.target))
    targetTab && targetTab.scrollIntoView()

    this.dispatchEvent(new CustomEvent('tabs:change', {
      detail: { tabSelector: tabClicked.dataset.target, activeTab: targetTab },
      bubbles: true
    }))
  }

  _attachTabLabels = (tabs = []) => {
    this.navContainer = this.shadowRoot.getElementById('nav')
    this.tabLabelElements = tabs.map(tab => {
      const tabEl = document.createElement('a')
      tabEl.innerHTML = tab.dataset.tabname
      tabEl.dataset.target = `#${tab.id}`
      tabEl.addEventListener('click', e => this.switchTab(e.target))
      return tabEl
    })
    this.navContainer.append(...this.tabLabelElements)
  }
}

customElements.define('hd-tabs', TabsComponent)
