import { useRedirectOnSuccess } from '@/src/mixins/useRedirectOnSuccess'
import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    useRedirectOnSuccess(this)
    this.getActiveStep()
    this.markActiveStepInSidebar()
  }

  getActiveStep () {
    const [step] = window.location.pathname.split('/').slice(-1)
    this.currentStep = step
  }

  markActiveStepInSidebar () {
    const activeNavElement = document.querySelector(`[data-step=${this.currentStep}]`)
    activeNavElement?.classList.add('active')
  }
}