import { Turbo } from '@hotwired/turbo-rails'
import "@rails/ujs";
import '../src/webcomponents'
import { Application } from "stimulus";
import { registerControllers } from "stimulus-vite-helpers";

// Turbo Drive configured as opt-in (not enabled globally)
// Use data-turbo="true" on forms and links to enable it if necessary
Turbo.session.drive = false

const application = Application.start();
const controllers = import.meta.globEager("../**/*_controller.js");
const components = import.meta.globEager("../../**/*_controller.js");
registerControllers(application, controllers);
registerControllers(application, components);
