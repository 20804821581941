/* global Newsroom */
import { Controller } from 'stimulus'
import {fetchStream, windowURL} from "@/src/utils/common";
import { diffString } from '../src/utils/diff'

export default class extends Controller {
  connect () {
    this.article_history = Newsroom.article_history
    this.project_history = Newsroom.project_history
  }

  showHistory(e) {
    e.preventDefault();
    let articleId = e.target.dataset["articleId"]

    fetchStream(
      "GET",
      `https://${window.location.hostname}/articles/${articleId}/history_tracker`,
      {
          target: "history_popup",
      },
      ()=> {
        document.querySelector('hd-modal#history-modal-template').open()
      }
    );
  }

  showDiff (event) {
    const { tabSelector, activeTab } = event.detail
    const { identifier, initialized } = activeTab?.dataset || {}
    if (initialized) return

    switch (tabSelector) {
      case '#article-exports':
        return false
      case '#project-changes':
        return this.projectHistoryDiff(activeTab)
      default:
        return this.articleHistoryDiff(identifier, activeTab)
    }
  }

  articleHistoryDiff (identifier, tab) {
    this.article_history.forEach(history => {
      const historyId = history._id.$oid
      if (identifier !== historyId || !tab) return

      const parentDiv = document.createElement('div')
      const p = document.createElement('p')
      const h = document.createElement('h2')
      const div = document.createElement('div')

      parentDiv.id = historyId
      parentDiv.classList.add('nrm-margin--b-24')
      p.textContent = `By: ${history.author.name} (${history.author.email})`
      p.classList.add('nrm-text--bold')
      h.innerHTML = this._getDiff(history.original.title, history.modified.title)
      div.innerHTML = this._getDiff(history.original.content, history.modified.content)
      parentDiv.append(p, h, div)
      tab.appendChild(parentDiv)
    })
    tab.dataset.initialized = true
  }

  projectHistoryDiff (tab) {
    this.project_history.forEach(history => {
      const historyId = history._id.$oid
      const tabPane = document.getElementById(`${historyId}`)
      const div = document.createElement('div')
      div.innerHTML = this._getAllDiff(history.original, history.modified)
      tabPane.appendChild(div)
    })
    tab.dataset.initialized = true
  }

  _getDiff (original = '', modified = '') {
    return diffString(original, modified)
  }

  _getAllDiff (original = {}, modified = {}) {
    const results = {}
    let htmlString = '<ul>'
    for (const prop in modified) {
      if (original[prop] && modified[prop]) {
        results[prop] = diffString(JSON.stringify(original[prop]), JSON.stringify(modified[prop]))
      }
    }
    for (const prop in results) {
      htmlString += `
        <li class="nrm-margin--t-8">
          <span class="nrm-text--bold nrm-text--capitalize">${prop}:</span>
          ${results[prop]}
        </li>`
    }
    htmlString += '</ul>'
    return htmlString.toString()
  }
}
