import { Controller } from "@stimulus/core";
import "./component.scss"

export default class extends Controller {
  static targets = ['container', 'modal', 'preview']

  initialize () {
    this.element?.hasAttribute('new-set') && this.modalTarget?.open() // Open modal for sets created via streams
  }

  connect () {
    // Open modal for default miniature set
    this.containerTarget.addEventListener('miniatures:edit-default', this.editSet.bind(this))
  }

  editSet () {
    this.modalTarget?.open()
  }

  closeModal () {
    this.modalTarget?.close()
  }

  updatePreviews (e) {
    const { values: miniatures } = e.detail
    this.previewTargets.forEach((item, index) => {
      const [img, input] = item.children
      img.src = miniatures[index]?.src || ''
      input.value = miniatures[index]?.value || ''
    })

    miniatures?.length
      ? this.containerTarget.removeAttribute('data-empty')
      : this.containerTarget.setAttribute('data-empty', '')
  }
}