import {
  fetchStream,
  toCamelCase,
  toTitleCase,
  windowURL,
} from "@/src/utils/common";
import { Controller } from "stimulus";

const DEFAULT_DIAGRAMATION_ELEMENTS = ['featured_image', 'miniatures', 'main_spec', 'read_more', 'extra_spec', 'paragraph', 'gallery', 'location']
export default class extends Controller {
  startingNode;

  connect() {
    this.element[toCamelCase(this.identifier)] = this;
    this.startingNode = document.querySelector(".media_data");
    this.sidebarElement = document.getElementById('sidebar-container');
  }

  getMediaController() {
    return this.uploadMediaController || document.querySelector(".upload_media").uploadMedia
  }

  turnInto(event) {
    const { type } = event.currentTarget.dataset;

    this.startingNode.querySelectorAll(".selected").forEach((box) => {
      const { formModel, formId } = box.dataset;
      if (formModel === type) {
        return;
      }

      fetchStream(
        "POST",
        `${windowURL}/convert`,
        {
          from: toTitleCase(formModel),
          to: toTitleCase(type),
          media_id: formId,
          element_id: box.parentNode.id,
        },
        (content) => this.getMediaController().formWatcher.collect(content)
      );
    });
  }

  changeProvider(event) {
    const { mediaProviderId } = event.currentTarget.dataset;
    const mediaProviderName = `© ${event.currentTarget.innerText}`;
    this.startingNode.querySelectorAll(".selected").forEach((form) => {
      form.querySelector(".caption").innerText = mediaProviderName;
      const caption = form.querySelector("[data-form-field=human_caption]");
      if (caption) {
        caption.setAttribute("placeholder", mediaProviderName);
      }
      const providerInput = form.querySelector(
        "[data-form-field=media_provider_id]"
      );
      providerInput.value = mediaProviderId;
      this.getMediaController().field_reconciliation(providerInput);
    });
  }

  delete () {
    /* eslint-disable-next-line  max-len */
    const message = 'If the media to be deleted has already been diagramated, please review the Diagramation step again.\nDo you want to proceed?'
    const confirmation = window.confirm(message)
    if (!confirmation) return

    this.startingNode.querySelectorAll('.selected').forEach(form => {
      const { formModel, formId } = form.dataset
      form.querySelectorAll('[data-form-field]').forEach(field => {
        field.setAttribute(
          'name',
          `delete_params[${toTitleCase(formModel)}][${formId}]`
        )
      })
      form.parentNode.classList.add('fade-out')
      setTimeout(() => form.parentNode.classList.add('hidden'), 500)
    })
  }

  select(event) {
    const { type } = event.currentTarget.dataset;
    const onSelect = (search, handleSelection) => {
      this.startingNode
        .querySelectorAll(search)
        .forEach((form) => handleSelection(form));
    };

    switch (type) {
      case "all":
        onSelect("[data-form-model]", (form) => form.classList.add("selected"));
        break;
      case "none":
        onSelect("[data-form-model]", (form) => form.classList.remove("selected"));
        break;
      default:
        onSelect(`[data-form-model=${type}]`, (form) => form.classList.toggle("selected"));
    }
  }

  clearSidebarSelections() {
    this.sidebarElement?.querySelectorAll('.selected')
      .forEach(el => el.classList.remove('selected'))
  }

  // Event listened by "media" and "elements" tabs, so that they update themselves accordingly
  updateSidebarSelections() {
    const updateSidebarEvent = new CustomEvent('diagramator:updatedByAutofill', {
      bubbles: true,
      cancelable: true
    })
    this.sidebarElement.dispatchEvent(updateSidebarEvent)
  }

  deleteAllDiagramationMedia() {
    document.querySelectorAll('.js-diagramator-sortable .js-removable-element')
      .forEach(el => el.remove())
    this.clearSidebarSelections()
  }

  autoFillDiagramation () {
    const elementsSidebar = document.getElementById('elements-sortable')
    const mediaSidebar = document.getElementById('media-sortable')
    const diagramatorContainer = document.getElementById('diagramator')
    if (!elementsSidebar || !diagramatorContainer) return

    this.deleteAllDiagramationMedia()
    this.clearSidebarSelections()

    const paragraphs = diagramatorContainer.querySelectorAll('[data-type=paragraph]')
    const { children: media } = mediaSidebar

    const diagramationFragment = DEFAULT_DIAGRAMATION_ELEMENTS.reduce((fragment, type) => {
      if (type === 'paragraph') {
        const elements = this._alternateMediaAndParagraphs(paragraphs, media)
        elements?.length && fragment.append(...elements)
      } else {
        const element = elementsSidebar.querySelector(`[data-type=${type}]`)
        element && fragment.append(element.cloneNode(true))
      }
      return fragment
    }, document.createDocumentFragment())

    diagramationFragment.querySelectorAll('section:not(.unsortable)')
      .forEach(item => item.setAttribute('draggable', true))

    diagramatorContainer.innerHTML = ''
    diagramatorContainer.appendChild(diagramationFragment)

    this.updateSidebarSelections()
  }

  _alternateMediaAndParagraphs(paragraphs = [], media = []) {
    const filteredMedia = Array.from(media).filter(el =>
      !el.matches('[data-type^=miniature_], [data-type=featured_image]'))

    return Array.from(paragraphs).reduce((arr, paragraph, index) => {
      const mediaElement = filteredMedia[index]
      mediaElement && arr.push(mediaElement.cloneNode(true))
      arr.push(paragraph)
      return arr
    }, [])
  }
}
