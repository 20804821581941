import { Controller } from 'stimulus'
import sortable from 'html5sortable/dist/html5sortable.es'

export default class extends Controller {
  static targets = ['container']

  connect () {
    this.elementsSortable = sortable('#elements-sortable', {
      copy: true,
      acceptFrom: false,
      customDragImage: (draggedElement, elementOffset, event) => ({
        element: draggedElement.querySelector('.sidebar-thumbnail'),
        posX: event.pageX - elementOffset.left,
        posY: event.pageY - elementOffset.top
      })
    })

    this.updateSelectedElements()
  }

  onElementRemoved (e) {
    const { type } = e.detail
    const element = this.containerTarget.querySelector(`[data-type=${type}]`)
    element?.classList.remove('selected')
    element?.setAttribute('draggable', true)
  }

  updateSelectedElements () {
    const diagramator = document.getElementById('diagramator')
    Array.from(diagramator?.children).forEach(el => {
      const { type } = el.dataset
      const element = this.containerTarget.querySelector(`[data-type=${type}]`)
      element?.classList.add('selected')
      element?.setAttribute('draggable', false)
    })
  }
}
