import Choices from "choices.js";
import { Controller } from "stimulus";
import { fetchStream, windowURL } from "@/src/utils/common";
import "choices.js/public/assets/styles/choices.min.css";
import "./component.scss";

export default class extends Controller {
  static targets = [
    "widgets",
    "widgetItem",
    "name",
    "title",
    "categories",
    "accounts",
    "products",
  ];

  connect() {
    this.itemContainerSelector = ".nrm-smart-widget__item";
    this.addStreamEndpoint = `${windowURL}/new_smart_widget`;
  }

  fetchProducts() {
    if (!this.productsActive) return

    const accountIds = this.accountsTarget.choices
      .getValue()
      .map((c) => c.value)
      .join(",");

    if (!accountIds) {
      this.productsTarget.choices.clearChoices();
      return;
    }

    this.productsTarget.choices.setChoices(
      async () => {
        try {
          const url = new URL(
            "/sw_specific_products/" + accountIds,
            location.href
          );
          const response = await fetch(url);
          const products = await response.json();

          return products.map((product) => ({
            label: `(${product.market.toUpperCase()}) ${product.title}`,
            value: product.id,
          }));
        } catch (err) {
          console.error(err);
        }
      },
      "value",
      "label",
      true
    );
  }

  add() {
    fetchStream("GET", this.addStreamEndpoint, {
      target: this.widgetsTarget.id,
    });
  }

  remove(event) {
    const closestElement = event.currentTarget.closest(
      this.itemContainerSelector
    );
    Array.from(closestElement.querySelectorAll("[data-next-action]")).forEach(
      (input) => {
        input.dataset.nextAction = "delete";
        this._buildInputName(input);
      }
    );
    closestElement.classList.add("hidden");
  }

  showProducts(event) {
    this.productsActive = true;
    this.fetchProducts();
    event.currentTarget.parentElement.classList.add('active')
  }

  widgetAdded(event) {
    Array.from(
      event.currentTarget.querySelectorAll("[data-next-action]")
    ).forEach((target) => this._buildInputName(target));

    Array.from(event.currentTarget.querySelectorAll(".nrm-choices")).forEach(
      (target) => this._buildChoices(target)
    );
  }

  _buildInputName(input) {
    const { fieldName, nextAction, objectId, objectFieldName, multiple } =
      input.dataset;
    const base = `${fieldName}[${nextAction}][${objectId}][${objectFieldName}]`;
    input.name = multiple ? base + "[]" : base;
  }

  _buildChoices (target) {
    const targetName = target.getAttribute(`data-${this.identifier}-target`)
    const targetElement = this[`${targetName}Target`]
    if (targetElement) {
      targetElement.choices = new Choices(target, {
        duplicateItemsAllowed: false,
        removeItemButton: true,
        searchResultLimit: 15
      })
    } else {
      /* eslint-disable-next-line  no-new */
      new Choices(target, { removeItemButton: true, searchResultLimit: 15 })
    }
  }
}
