import {Controller} from "stimulus";
import {fetchStream, windowURL} from "@/src/utils/common";
import "./component.scss";

export default class extends Controller {
    static targets = ["container", "htmlItem", "htmlInput"]

    connect() {
        this.htmlInputTargets.forEach(target => this._buildInputName(target));
    }

    add() {
        fetchStream(
            "GET",
            `${windowURL}/new_html_element`,
            {
                target: this.containerTarget.id,
            },
            (content) => {
                Array.from(content.querySelectorAll("[data-next-action]")).forEach(input => this._buildInputName(input))
            }
        );
    }

    remove(event) {
        const closestElement = event.currentTarget.closest(".nrm-html__item")
        Array.from(closestElement.querySelectorAll("[data-next-action]")).forEach(input => {
            input.dataset.nextAction = "delete"
            this._buildInputName(input)
        })
        closestElement.classList.add("hidden")
    }

    _buildInputName(input) {
        const {fieldName, nextAction, objectId, objectFieldName} = input.dataset
        input.name = `${fieldName}[${nextAction}][${objectId}][${objectFieldName}]`
    }
}