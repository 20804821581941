import { Controller } from 'stimulus'
import './component.module.scss'

export default class extends Controller {
  static targets = ['container']

  removeElement (e) {
    e.preventDefault()
    const { type } = this.containerTarget.dataset
    const removedEvent = new CustomEvent('diagramator:elementRemoved', {
      detail: { type },
      bubbles: true,
      cancelable: true
    })
    this.containerTarget.dispatchEvent(removedEvent)
    this.containerTarget?.remove()
  }

  onEdit (e) {
    const message = "You're about to leave this step and any unsaved changes will be lost. Do you still want to leave diagramation?"
    const confirmation = window.confirm(message)
    if (!confirmation) e.preventDefault()
  }
}
