import { Controller } from 'stimulus'
import sortable from 'html5sortable/dist/html5sortable.es'

export default class extends Controller {
  static targets = ['container']

  connect () {
    this.diagramator = document.getElementById('diagramator')
    this.mediaSortable = sortable('#media-sortable', {
      copy: true,
      acceptFrom: false
    })
    this.updateSelectedMedia()
  }

  onMediaRemoved (e) {
    const { type } = e.detail
    const media = this.containerTarget.querySelector(`[data-type=${type}]`)
    media?.classList.remove('selected')
    media?.setAttribute('draggable', true)
  }

  updateSelectedMedia () {
    Array.from(this.containerTarget.children).forEach(el => {
      const { type } = el.dataset
      const media = this.diagramator.querySelector(`[data-type=${type}]`)
      if (media) {
        el.classList.add('selected')
        el.setAttribute('draggable', false)
        media.setAttribute('draggable', true)
      }
    })
  }
}
