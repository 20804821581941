export const useNamespacedClass = (controller) => {
  Object.assign(controller, {
    namespaceClass(className) {
      controller.cachedClasses ||= {};
      return className
        .split(" ")
        .map((name) => {
          if (!controller.cachedClasses[name]) {
            controller.cachedClasses[
              name
            ] = `c-${controller.identifier}-${name}`;
          }

          return controller.cachedClasses[name];
        })
        .join(" ");
    },
  });
};
