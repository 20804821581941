import { useRedirectOnSuccess } from "@/src/mixins/useRedirectOnSuccess";
import {
  fetchWrapper
} from "@/src/utils/common";
import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ['container', 'title', 'excerptCheck'];

  connect() {
    useRedirectOnSuccess(this);
    this.sync_excerpt();
    this.autosave();
    this.setArticleSlug();
    this.excerptCheckTarget.addEventListener('change', this.sync_excerpt);
    this.validateRelatedArticle()
  }

  sync_excerpt(){
    let checkbox = document.querySelector("#insert_excerpt");
    let excerpt   = document.querySelector(".post_content .excerpt");

    if(checkbox.checked){
      excerpt.classList.add("fade-in");
      excerpt.classList.remove("fade-out");
      excerpt.style.display = "";

    } else {
      excerpt.style.display = "none";
      excerpt.classList.remove("fade-in");
      excerpt.classList.add("fade-out");
    }
  }

  autosave(){
    setInterval (()=>{
      let article_content = document.querySelector("[id$=article_content]");
      const autosave_alert = document.getElementById("autosave_alert");

      if (article_content.textLength >= 15){
        tinyMCE.triggerSave();
        autosave_alert.classList.remove("fade-out");
        autosave_alert.classList.add("fade-in");

        let pathname = location.pathname;
        pathname = pathname.replace("wizard/post_content", "autosave_article");
        let url = `${location.origin}/api/v1${pathname}`;
        let payload = { content: article_content.value,}

        fetchWrapper("POST", new URL(url), payload)
          .then(() => {
            autosave_alert.classList.remove("fade-in");
            autosave_alert.classList.add("fade-out");
          });
      }
    }, 180000);
  }

  setArticleSlug(){
    if (this.titleTarget.value.length > 0){
      this.titleTarget.addEventListener('focusout', ()=>{
        let articleType = this.titleTarget.dataset.articleType;
        let exported = this.titleTarget.dataset.exported;
        if (exported === "false")
          document.getElementById(`${articleType}_slug`).value = this.titleTarget.value;
      })
    }

  }

  enableForm (enabled) {
    const saveBtns = document.querySelectorAll("[name='next_action'], [name='next_action[step]']")
    saveBtns.forEach(btn => btn.disabled = !enabled)
    return enabled
  }

  resetRelatedArticle() {
    const validationMarker = document.getElementById('approved_related_article')
    validationMarker.style.backgroundColor = 'gray'
    this.enableForm(true)
  }

  toggleRelatedArticle (activate) {
    const validationMarker = document.getElementById('approved_related_article')
    validationMarker.style.backgroundColor = activate ? 'var(--green)' : 'var(--red)'
  }

  validateRelatedArticle () {
    const relatedArticle      = document.getElementById('related_article')
    const relatedArticleTitle = document.getElementById('related_article_title')

    relatedArticle?.addEventListener('input', e => {
      const { value } = e.target
      if (!value && !relatedArticleTitle.value) { this.resetRelatedArticle() }
      else{
        this.toggleRelatedArticle(value.match(/www.archdaily.|www.architonic./))
      }
    })

    relatedArticleTitle?.addEventListener('input', e => {
      const { value } = e.target
      if (!value && !relatedArticle.value) { this.resetRelatedArticle() }
      else{
        this.toggleRelatedArticle(value && relatedArticle.value)
      }
    })
  }
}
