import { Controller } from 'stimulus'
import sortable from 'html5sortable/dist/html5sortable.es'
import './component.scss'

export default class extends Controller {
  static targets = ['container']

  connect () {
    this.previewBtn = document.getElementById('toggle-diagramation-preview')
    this.previewBtn.addEventListener('click', e => this.toggleDiagramatorMode(e))

    this.diagramatorSortable = sortable('.js-diagramator-sortable', {
      acceptFrom: '.js-diagramator-sortable, .js-sidebar-sortable',
      placeholder: '<div class="diagramator__placeholder">Drop element here</div>',
      customDragImage: (draggedElement, elementOffset, event) => {
        return {
          element: draggedElement.querySelector('main'),
          posX: event.pageX - elementOffset.left,
          posY: event.pageY - elementOffset.top
        }
      }
    })

    this.diagramatorSortable[0]?.addEventListener('sortupdate', this._onSortUpdate)

    const unsortableItems = this.containerTarget.querySelectorAll('.unsortable')
    unsortableItems?.forEach(item => item.removeAttribute('draggable'))
  }

  toggleDiagramatorMode ({ target: button }) {
    this.containerTarget.classList.toggle('preview-mode')
    const previewOn = button.hasAttribute('data-preview')
    if (previewOn) {
      button.removeAttribute('data-preview')
      button.innerHTML = 'Preview Mode'
    } else {
      button.setAttribute('data-preview', true)
      button.innerHTML = 'Edit Mode'
    }
  }

  _onSortUpdate (e) {
    const { origin: { container, index, items } = {}, destination: { index: destIndex } = {}, item } = e.detail
    if (!container?.matches('.js-sidebar-sortable') || destIndex < 0) return false

    items[index]?.classList.add('selected')
    items[index]?.setAttribute('draggable', false)
    item.setAttribute('draggable', true)
  }
}
